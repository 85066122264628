<template>
    <div id="app_sign_detail_id">
        <!-- 多媒体消息签名详情 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息签名详情</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-table :data="tableData_base" :show-header="false" style="width: 100%" size="mini">
                        <el-table-column fixed prop="label" width="110" align="center"> </el-table-column>
                        <el-table-column prop="show_value" min-width="200" align="right"> 
                            <template slot-scope="scope">
                                <template v-if="scope.row.name == 'upload_file'">
                                    <template v-if="scope.row.show_value != ''">
                                        <el-popover placement="top-start" title="" trigger="hover">
                                            <img :src="scope.row.show_value" alt="" style="width: 250px; height: 300px">
                                            <img slot="reference" :src="scope.row.show_value" style="width: 100px; height: 150px">
                                        </el-popover>
                                    </template>
                                    <template v-else>
                                        <el-image style="width: 100px; height: 150px;background: rgb(205, 205, 205);text-align: center;line-height: 140px;">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </template>
                                </template>
                                <template v-else>
                                    <span v-html="scope.row.show_value"></span>
                                </template>
                             </template>
                        </el-table-column>
                    </el-table>                    
                </el-row>

            </el-row>

        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    components: {

    },
    props:{
        prop_change_i:0,
        prop_sign_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            tableData_base:[],//签名详细信息数据
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);    
        },
         //数据清除
        dataClear(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
        },

        //查询
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化

           //请求接口
            API.MediaSignServlet({
                param: "mediaSign",
                sign_id: this.prop_sign_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    var show_value = "";
                    table_obj = {label:"签名id",show_value:res.detail.signword_id,value:res.detail.signword_id,name:"signword_id"};
                    this.tableData_base.push(table_obj);

                    var table_obj = {label:"审核结果",show_value:res.detail.state,value:res.detail.state,name:"state"};
                    this.tableData_base.push(table_obj);
                    
                    var table_obj = {label:"省份",show_value:res.detail.province,value:res.detail.province,name:"province"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"地市",show_value:res.detail.city,value:res.detail.city,name:"city"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"服务代码",show_value:res.detail.service_code,value:res.detail.service_code,name:"service_code"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"签名内容",show_value:res.detail.signword,value:res.detail.signword,name:"signword"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"实际发送企业",show_value:res.detail.user_company_name,value:res.detail.user_company_name,name:"user_company_name"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"信息安全行业",show_value:res.detail.rcs_industry,value:res.detail.rcs_industry,name:"rcs_industry"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"行业分类",show_value:res.detail.industry,value:res.detail.industry,name:"industry"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"客户分类",show_value:res.detail.customer_type,value:res.detail.customer_type,name:"customer_type"};
                    this.tableData_base.push(table_obj);

                    table_obj = {label:"授权函",show_value:res.detail.upload_file,value:res.detail.upload_file,name:"upload_file"};
                    this.tableData_base.push(table_obj);
           
                }
                
            });
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
</style>